.container {
  width: 100%;
  position: relative;
  display: flex;
  z-index: 1;
}
.container ul {
  flex: 1;
}
.adminContainer {
  width: 700px;
  min-width: 700px;
}
#backButton {
  position: absolute;
  top: 15px;
  left: 15px;
}
#onepageDemo {
  /* max-width: calc(100% - 800px); */
  /* width: calc(100% - 800px); */
  padding: 50px;
  width: 95%;
  max-width: 95%;
  margin: 0 auto;
  /* margin-right:100px; */
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--grey-10);
}
#onepageDemo .menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.colorBlock {
  display: inline-flex;
  margin: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/**** job application ****/

.container #onepageDemo section#jobApplication .card__container {
  grid-template-columns: 1fr 1fr 1fr;
}

/**** ourwork ****/

/**** news ****/

.container #onepageDemo section#newsArticles {
  padding: 0;
}
.container #onepageDemo section#newsArticles h2 {
  padding: 0;
  margin: 1em;
}
.container #onepageDemo section#newsArticles.temp-4 .container {
  grid-template-columns: repeat(auto-fill, minmax(350px, 350px));
}
.container #onepageDemo section#newsArticles.temp-4 .container::before {
  width: 350px;
  height: 400px;
  content: "Nieuws";
}
.container #onepageDemo section#newsArticles.temp-4 .container div:first-child {
  grid-column-start: unset;
}
/**** social news ****/

.container #onepageDemo section#socialNews .sectionWrapper {
  grid-template-columns: 1fr 1fr;
}

/**** review ****/

.container #onepageDemo section#review.temp-1 .card-container {
  grid-template-columns: 1fr;
  padding: 0 1em;
}

.container #onepageDemo section#review.temp-2 {
  width: 100%;
}

/**** quote ****/

.container #onepageDemo section#quote .slick-list {
  width: 100%;
}
.container #onepageDemo section#quote .slick-slide {
  width: 100%;
}
.container #onepageDemo section#quote .slick-track {
  width: unset;
}
.container #onepageDemo section#quote.temp-2 #foto img {
  width: unset;
  height: 100%;
}

/**** about us ****/

.container #onepageDemo section#aboutUs.temp-4 .aboutContent {
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.container #onepageDemo section#aboutUs.temp-4 .aboutImg {
  height: 550px;
}

.container #onepageDemo section#aboutUs.temp-3 .aboutContent {
  margin: 0;
}
.container #onepageDemo section#aboutUs.temp-4 .aboutImg {
  height: 700px;
}

.container #onepageDemo section#review.temp-2 .container {
  overflow: hidden;
  width: 520px;
}

/**** media ****/
.container #onepageDemo section#socialNews .sectionWrapper {
  grid-template-columns: 1fr 1fr;
}
.container #onepageDemo section#socialNews .sn_image {
  height: unset;
}

.container #onepageDemo section#ourWork .items {
  grid-template-columns: 1fr;
}

@media (max-width: 1650px) {
  .container #onepageDemo section#jobApplication .card__container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1400px) {
  .container #onepageDemo section#jobApplication .card__container,
  .container #onepageDemo section#jobApplication.temp-3 .card__container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .container #onepageDemo section#ourWork .items {
    grid-template-columns: 1fr 1fr;
  }
  .container #onepageDemo section#jobApplication .card-image__container img {
    width: 100%;
    height: unset;
  }

  .container #onepageDemo section#jobApplication .card-image__container {
    height: 150px;
  }
  .container #onepageDemo section#socialNews .sectionWrapper {
    grid-template-columns: 1fr 1fr;
  }

  .container #onepageDemo div.small {
    flex-direction: column;
    margin-bottom: 1.5em;
    border: 1px solid var(--main) !important;
    border-radius: 5px;
  }
  .container #onepageDemo div.small div.small-part {
    width: 100% !important;
    max-width: unset !important;
  }
}

@media (max-width: 1200px) {
  .container #onepageDemo section#ourWork {
    grid-template-columns: 1fr;
  }
  .container #onepageDemo section#ourWork .item-image__container img {
    height: unset;
    width: 100%;
  }
  .container #onepageDemo section#jobApplication .card__container {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media (max-width: 900px) {
  .container #onepageDemo section#jobApplication .card-image__container {
    height: 100px;
  }
  .container #onepageDemo section#socialNews .sectionWrapper {
    grid-template-columns: 1fr;
  }
  .container #onepageDemo section#jobApplication .card-text .card__title {
    font-size: larger;
    font-weight: 600;
  }
  .container #onepageDemo section#ourWork .item-image__container img {
    height: 100%;
    width: unset;
  }

  .container #onepageDemo section#jobApplication .card__container {
    grid-template-columns: 1fr !important;
  }

  .container #onepageDemo header#home div.signature {
    padding: 0 1em;
  }
  .container #onepageDemo section#ourWork .items {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 700px) {
  .container #onepageDemo section#review.temp-2 .container {
    width: 250px !important;
    overflow: hidden;
  }
  .container #onepageDemo section#review.temp-2 .container .reviewslist {
    width: 250px !important;
  }
  .container #onepageDemo section#review.temp-2 .container .reviewslist {
    width: 250px !important;
  }
  .container #onepageDemo section#review.temp-2 .slick-slide {
    width: 250px;
  }
}
@media (max-width: 500px) {
  .container #onepageDemo div.menu {
    display: flex;
    flex-direction: column;
  }

  .container #onepageDemo section#newsArticles .article {
    width: 220px;
  }
  .container #onepageDemo section#newsArticles .article a {
    padding-bottom: 1em;
  }
  .container #onepageDemo section#newsArticles.temp-1 .article h3,
  .container #onepageDemo section#newsArticles.temp-2 .article h3,
  .container #onepageDemo section#newsArticles.temp-4 .article h3 {
    font-size: 1em;
  }
  .container #onepageDemo section#newsArticles .article figure,
  .container #onepageDemo section#newsArticles .article {
    height: unset;
  }
  .container #onepageDemo {
    padding: 10px;
  }
  section#ourWork.temp-2 .items .item .item-content h3 {
    display: block;
    margin: 10px 0 !important;
    width: 100%;
  }
  section#ourWork .item .item-content h3:before,
  section#ourWork .item .item-content h3:after {
    content: "";
    display: none !important;
  }
}

@media (max-width: 300px) {
  .container #onepageDemo div.menu {
    display: flex;
    flex-direction: column;
  }
}
