/* CSS for the add module part*/

.AddModule_wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.AddModule_wrapper::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: 0.7;
    z-index: 9;
}
.AddModule_wrapper .addModule {
    position: absolute;
    top: 50%;
    left: 50%;
    background: white;
    z-index: 10;
    width: 80%;
    transform: translate(-50%,-50%);
    height: 80%;
}
.addModule #title { 
    width: calc(100% - 20px);
    margin: 10px;
    display: flex;
    justify-content: space-between;
}
.addModule .icon { 
    cursor: pointer;
    padding: 2px;
}
.addModule .items { 
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 165px);
    overflow-y: scroll;
    padding: 15px;
}
.sortableHelper.item,
.addModule .items .item { 
    margin: 20px;
    width: 400px;
    min-width: 400px;
    border: 1px solid var(--grey-10);
    border-radius: 5px;
    height: 200px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    background-color: white;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
}
.sortableHelper.item .icon,
.addModule .items .item .icon{
    align-self: center; 
}
.sortableHelper.item figure,
.addModule .items figure {
    width: 300px;
    position: relative;
    height: 100px;
}
.sortableHelper.item img,
.addModule .items figure img {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}
/* This line won't break the image when sorting */

/* .sortableHelper img { width: 100%; height: 50%;} */


.addModule #footer {
    display: flex;
    justify-content: flex-end;
    height: calc(100% - 652px);
    align-items: center;
    padding: 0 50px;
}
